import React from "react";
import Amplify, {Storage} from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import config from "./aws-exports";
Amplify.configure(config);

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            imageName: "",
            imageFile: "",
            response: ""
        };

        this.uploadImage = this.uploadImage.bind(this);
        this.uploadImagePrivate = this.uploadImagePrivate.bind(this);

        // Call Startup Functions
        this.readFromStorage()
    }
    uploadImage()  {
        Storage.put(`userimages/${this.upload.files[0].name}`,
            this.upload.files[0],
            { contentType: this.upload.files[0].type })
            .then(result => {
                this.upload = null;
                this.setState({ response: "Success uploading file!" });
            })
            .catch(err => {
                this.setState({ response: `Cannot uploading file: ${err}` });
            });
    };

    readFromStorage() {
        Storage.list('').then(
            result => {
                console.log('data from S3: ', result)
            }
        );
        Storage.list('', { level: 'private' }).then(
            result => {
                console.log('Private data from S3: ', result)
            }
        );

    }
    uploadImagePrivate()  {
        Storage.vault.put(`userimages/${this.upload.files[0].name}`,
            this.upload.files[0],
            { contentType: this.upload.files[0].type })
            .then(result => {
                this.upload = null;
                this.setState({ response: "Success uploading file!" });
            })
            .catch(err => {
                this.setState({ response: `Cannot uploading file: ${err}` });
            });
    };

    render() {
        return (
            <div>
                <h1>Internal App</h1>
                <h2>S3 Upload example...</h2>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    ref={ref => (this.upload = ref)}
                    onChange={e =>
                        this.setState({
                            imageFile: this.upload.files[0],
                            imageName: this.upload.files[0].name
                        })
                    }
                />
                <input value={this.state.imageName} placeholder="Select file" />
                <button
                    onClick={e => {
                        this.upload.value = null;
                        this.upload.click();
                    }}
                    loading={this.state.uploading}
                >
                    Browse
                </button>

                <button onClick={this.uploadImage}> Upload File </button>
                <button onClick={this.uploadImagePrivate}> Upload File Private</button>

                {!!this.state.response && <div>{this.state.response}</div>}
            </div>
        );
    }
}

export default withAuthenticator(App, true);