// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:ff0aaa9f-0188-476a-a257-db551741a4c8",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_eoDBxBK8V",
    "aws_user_pools_web_client_id": "52abgfus8r31tiavmeb3vb16ci",
    "oauth": {
        "domain": "boardportaldba64f3a-dba64f3a-dev.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "myapp://",
        "redirectSignOut": "myapp://signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "boardportal1251add4f7a3469d9f3ee5ab5e06ab3e-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "boardportal-20190621143235-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d31w6kfmnc5k2k.cloudfront.net"
};


export default awsmobile;
